<style>
.custom-red {
  background-color: #FFCFD8;
}
</style>
<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in data"
              v-bind:class="[background(tr)]"
              
            >
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-button
                v-if="dataId == tr.id"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >

              <vs-td>
                <vs-button
                  v-if="dataId != tr.id"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                ></vs-button>
              </vs-td>
              <vs-td>
                {{ tr.code }}
              </vs-td>
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.amount) }}
              </vs-td>
              <vs-td>
                <feather-icon
                  v-if="tr.balance < 0"
                  icon="MinusIcon"
                  svgClasses="h-3 w-3"
                  style="color: red; font-weight: bold"
                ></feather-icon>
                {{ priceFormat(tr.balance) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="10"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <form-payment
              :invoice="selectedData"
              @closeDetail="closeDetail"
            ></form-payment>
            <detail :data-id="dataId" :selected="selectedData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import detail from "./detail.vue";

export default {
  components: {
    detail,
  },
  props: [
    "territory",
    "salesChannel",
    "customerCategory",
    "startDate",
    "endDate",
  ],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      header: [
        {
          text: "Action",
          // width: '5%'
          value: "code",
        },
        {
          text: "Code Customer",
          // width: '5%'
          value: "code",
        },
        {
          text: "Name Customer",
          value: "name",
        },
        {
          text: "Credit Limit Amount",
          value: "amount",
        },
        {
          text: "Credit Balance",
          value: "balance",
        },
      ],
      responseData: {},
      isActive: 1,

      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      dataId: 0,
    };
  },
  watch: {},
  mounted() {
    this.reloadData(this.params);
  },
  methods: {
    clickTr(val) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      console.log("val.id", val);
      this.dataId = Number(val.id);
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    background(tr){
      if(this.dataId == tr.id){
        return "colored"
      }else {
        // const persen = parseFloat(tr.balance)/parseFloat(tr.amount)
        // if(tr.credit_limit < 0){
        //   return "custom-red"
        // }
        if(tr.balance < 0){
          return "custom-red"
        }
        // else if (persen < 0.5){
        //   return "yellow"
        // }
        return ""
      }
    },
    reloadData(params) {
      console.log(this.params.sort);
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/report/customer-limit", {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.customer_limit;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
</style>